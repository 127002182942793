.content {
	margin-right: 100px;
	padding: 30px 90px 1em 90px;
}

@media only screen and (max-width: 1200px) {
	.content {
		padding-right: 90px;
		padding-left: calc(3px + (90 - 3) * ((100vw - 300px) / (1200 - 300)));
		margin-right: calc(0px + (100 - 0) * ((100vw - 550px) / (1200 - 550)));
	}
}

@media only screen and (max-width: 550px) {
	.content {
		margin-right: 0;
		padding-right: calc(3px + (90 - 3) * ((100vw - 450px) / (550 - 450)));
	}
}
